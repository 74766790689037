<!-- moments -->
<template>
  <div class="content has_breadcrumb journal-inner">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          name: 'Journal',
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage @forward="forward" @next="next" pageType="1" title="Journal">
      <div slot="topAppBar">
        <month-switch @change="monthChange" :defaultVal="defSwitchVal" />
        <search />
      </div>
      <BaseList title="Classes" :data="list" @onClickItem="itemClick" v-loading="loading" :type="1">
        <div slot="empty" style="text-align: center">
          <div
            style="
              width: 213px;
              text-align: center;
              border-radius: 50%;
              
              line-height: 200px;
          margin: auto;
          margin-bottom: 12px;
          margin-top: 80px;
            "
          >
            <img
              style="width: 100%"
              :src="require('@/assets/images/products/noData.svg')"
            />
          </div>
          <p style="font-size: 22px;color:rgba(32,33,36,0.5);font-family: Roboto;">No data for the day.</p>
        </div>
      </BaseList>

    </GgPage>
  </div>
</template>

<script>
import moment from 'moment';
import { Ajax } from "@/common";
import { mapState } from "vuex";
import monthSwitch from './modules/monthSwitch'
import search from './modules/search'
export default {
  components: {
    monthSwitch,
    search
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  data() {
    return {
      defSwitchVal: 0,
      loading: false,
      date:moment(),
      list: [],
    };
  },
  created() {
    this.initMonthSwitchVal()
  },
  methods: {
    forward(val){
      console.log(val);
    },
    next(val){
      console.log(val);
    },
    initMonthSwitchVal(){
      let journalDate = sessionStorage.getItem('journal_date')
      if(journalDate){
        this.date = moment(journalDate)
        this.defSwitchVal = this.date
      }else{
        var _def = moment()
        if(this.$route.query.time && this.$route.query.time.length == 13){
          _def = moment(parseInt(this.$route.query.time))
        }
        this.date = _def
        this.defSwitchVal = _def
        sessionStorage.setItem('journal_date', _def)
      }

      // if(!this.date){
      //   var _def = moment()
      //   if(this.$route.query.time && this.$route.query.time.length == 13){
      //     _def = moment(parseInt(this.$route.query.time))
      //   }
      //   this.date = _def
      //   this.defSwitchVal = _def
      // }
      
      this.fetchData()
    },

    monthChange(val){
      this.date = val
      sessionStorage.setItem('journal_date', moment(val))
      this.fetchData()
    },

    itemClick(item){
      if(item.id!=0){
        sessionStorage.setItem("journalClassId", item.id)
        sessionStorage.setItem("journalYear", item.year)
        sessionStorage.setItem("journalClassName", item.leading.text)
        this.$router.push('/journal/class/'+item.id+'?time='+this.date)
      }
    },

    fetchData(){
      this.loading = true;
      Ajax.get("journal/class/staffV1", {
        staffId:this.user.userId,
        pageNum: 1,
        pageSize: 1000,
        schoolId:this.user.locationId,
        startDate: moment(this.date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
      })
		  .then((res) => {
		    this.loading = false;
        if (res.code == "0000") {
        
          this.list = res.data.map(o=>{
            return {
              id:o.classId || 0,
              year: o.year,
              leading: {
                icon: o.classId?'':'iconatt_all',
                bgColor: o.themeColor || '#B4B4B4',
                color:o.classId?'':'#437AD1',
                text: o.className,
              },
              classStatus: o.classStatus,
              list: [
                {
                  text: o.studentCount,
                  subText: "Students",
                },
                {
                  text: o.newsletterCount,
                  subText: "Posted newsletters",
                },
                {
                  text: o.portfolioCount,
                  subText: "Posted portfolios",
                },
              ],
              trailing: {
              },
            }
          })
        
        }
		  })
		  .catch(() => {
		    this.loading = false;
		  });
     
    }
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .ggPage-main-center .base-list{
  padding-bottom:112px!important;
  .title{
    font-family: 'opensans-semibold';
    margin-left:0;
    padding-left:10px;
  }
  ul li {
    border-top:1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    &:nth-child(2) {
      border-bottom: 1px solid #e0e0e0;
    }
    &:nth-last-child(1) {
      border-bottom: 2px solid #e0e0e0;
    }
  }
}

</style>