<template>
  <div class="content has_breadcrumb">
    <!-- <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        }, {
          routeName: 'announcement',
          name: activeIndex,
        }
      ]"
      slot="breadcrumb"
    /> -->
    <GgPage
      topType="location"
      pageType="6"
      title="Announcements"
    >
      <div slot="topAppBar"><search :query="query"></search></div>
      <!-- <template v-slot:left>
        <div class="mar-l-b" v-if="activeIndex != 'Create'">
          <gg-create-button @click="openDialogAdd(null)" label="Create announcement" />
        </div>
        <gg-flex-menus
          :menus="menuDatas"
          @select="onMenuSelect"
          :default-active="activeIndex"
        />
      </template> -->

      <div class="collapse-list" v-infinite-scroll="loadAnnouncement" :style="'padding-right:200px; height:' + pageHeight + 'px;'">
        <p class="title">Search results</p>
        <div class="announcement-inner">
          <div class="section-inner" v-loading="loading">
            
            <div class="section-box" :class="item.select === true ? 'select' : ''" v-for="(item, index) in announcementlist" :key="index">
              <div class="section-head clearfix" @click="boxSelectFun(index, $event)">
                <div class="section-head-l">
                  <div class="section-title" v-if="item.status === 'archive'">{{ htmlDecode(item.title) || '-' }}</div>
                  <div class="section-title" v-else @click="todetail(item.id, index, item.status)">{{ htmlDecode(item.title) }}</div>
                  <div class="section-des">
                    <div class="avatar" v-if="item.publishUserPhoto"><img :src="item.publishUserPhoto"></div>
                    <div class="no" v-else>
                      {{ item.publishUserFirstName ? item.publishUserFirstName.substring(0, 1).toUpperCase() : '-' }}{{ item.publishUserLastName ? item.publishUserLastName.substring(0, 1).toUpperCase() : '-' }}
                    </div>
                    <div class="name">{{ item.publishUserFirstName || '' + ' ' + item.publishUserLastName || '' }}</div>
                    <div class="tag">{{ item.roleName }}</div>
                    <div class="date">posted on {{ momentTime(item.releaseTime) }}</div>
                  </div>
                </div>

                <div class="section-head-r">
                  <ul class="section-item">
                    <li>
                      <div class="val">{{ item.sumStudents }}</div>
                      <div class="tit">Students</div>
                    </li>
                    <li>
                      <div class="val">{{ item.sumClass }}</div>
                      <div class="tit">Classes</div>
                    </li>
                    <li>
                      <div class="options" v-if="item.questionContent.options.length > 1">
                        <div class="val">{{ (!item.respondedRate || item.respondedRate === '0%') ? '-' : item.respondedRate }}</div>
                        <div class="tit" >Viewed</div>
                      </div>
                      <div class="options" v-else>
                        <div class="val">{{ (!item.respondedRate || item.respondedRate === '0%') ? '-' : item.respondedRate }}</div>
                        <div class="tit" >Responded</div>
                      </div>
                    </li>
                  </ul>

                  <div class="section-col" v-if="menuDataJin[item.status].length > 0">
                    <gg-menu-bar>
                      <i class="iconmore_vert-24px iconfont" />
                      <template slot="menus">
                        <gg-menu-bar-item
                          v-for="(menuitem, key) in menuDataJin[item.status]"
                          @click="menuClick(menuitem, item, index)"
                          :disabled="menuitem.isDisabled"
                          :key="key"
                          ><span>{{ typeof menuitem === 'object' ? menuitem.label : menuitem }}</span></gg-menu-bar-item
                        >
                      </template>
                    </gg-menu-bar>
                  </div>

                </div>
                
              </div>
              <div class="section-body">
                <div class="section-mail" v-html="htmlDecode(item.body)"></div>
                <div class="section-file">
                  <div class="file-list-inner">
                    <div class="file-box" @click="viewfile(fileitem)" v-for="(fileitem, fileindex) in item.addFile" :key="fileindex">
                      <div class="file-head">
                        <pdf
                          style="width: 103px; height: 72px; text-align: center;" 
                          class="pdf-preview" 
                          v-if="returnFileFormat(fileitem.url) === 'pdf'" 
                          :id="fileitem.id" 
                          :page="1" 
                          :src="fileitem.url" />
                        <el-image
                          v-else
                          style="width: 103px; height: 72px;"
                          :src="fileitem.url"
                          fit="cover">
                        </el-image>
                      </div>
                      <div class="file-body">
                        <div class="file-name">File name: {{ fileitem.name || '-' }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="announcementlist.length === 0 && !loading" slot="empty" style="text-align: center">
              <div
                style="
                  display:inline-block;
                  width: 213px;
                  text-align: center;
                  border-radius: 50%;
                  margin-top: 210px;
                  margin-bottom: 22px;
                  line-height: 200px;
                "
              >
                <img
                  style="width: 100%"
                  :src="require('@/assets/images/moments/empty_photo.png')"
                />
              </div>
              <p style="font-size: 22px;color: #5f6368;font-family: 'Roboto';">No announcement yet.</p>
            </div>

          </div>

        </div>
      </div>

    </GgPage>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />

    <gg-Dialog width="580px" :visible.sync="isRestoreShow" title="Restore announcement?" class="draft-dialog">
      <div class="dialogContent">Once restored, the announcement will be shown in "Posted".</div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="isRestoreShow = false">Cancel</gg-cancel-button>
        <gg-submit-button @click="updateAnnouncementStatus('post')">Restore</gg-submit-button>
      </span>
    </gg-Dialog>

    <gg-Dialog width="580px" :visible.sync="isArchiveShow" title="Archive announcement?" class="draft-dialog">
      <div class="dialogContent">Once archived, the announcement will move to archived.</div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="isArchiveShow = false">Cancel</gg-cancel-button>
        <gg-submit-button @click="updateAnnouncementStatus('archive')">Archive</gg-submit-button>
      </span>
    </gg-Dialog>

    <gg-Dialog width="580px" :visible.sync="isRecallShow" title="Recall announcement?" class="draft-dialog">
      <div class="dialogContent">Once recalled, parents will not be able to see the announcement and the announcement will move to draft.</div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="isRecallShow = false">Cancel</gg-cancel-button>
        <gg-submit-button @click="updateAnnouncementStatus('draft')">Recall</gg-submit-button>
      </span>
    </gg-Dialog>

     <gg-Dialog width="580px" :visible.sync="isPostShow" title="Post announcement?" class="draft-dialog mp-dialog">
      <div class="dialogContent">Once the announcement is posted, all the parents of the selected classes will be able to view it.</div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="isPostShow = false">Cancel</gg-cancel-button>
        <gg-submit-button @click="updateAnnouncementStatus('post')">Post</gg-submit-button>
      </span>
    </gg-Dialog>

    <gg-Dialog width="580px" :visible.sync="isDelectShow" title="Delete announcement?" class="draft-dialog mp-dialog">
      <div class="dialogContent">Once deleted, you will not be able to recover the data.</div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="isDelectShow = false">Cancel</gg-cancel-button>
        <gg-submit-button @click="removeAnnouncement">Delete</gg-submit-button>
      </span>
    </gg-Dialog>

    <Snackbar :visible.sync="loadingShow" :content="loadingText" />
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import search from './modules/search'
import moment from 'moment'
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { vi } from 'date-fns/esm/locale';
import { set } from 'date-fns';
import { hasPermission } from './utils/utils.js';
export default {
  components: {
    pdf,
    search
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user
    }),
  },
  watch:{
    '$route':'getAnnouncement'
  },
  data() {
    return {
      saveobj: {},
      nowIndex: 0,
      pageHeight: 0,
      pages: 0,
      pageNum: 1,
      rootPath: process.env.VUE_APP_fileBasePath,
      query: {},
      loading: false,
      delId: '',
      isRestoreShow: false,
      isArchiveShow: false,
      isRecallShow: false,
      isDelectShow: false,
      isPostShow: false,
      status: {
        'DRAFT': 0,
        'POSTED': 1,
        'ARCHIVE': 2
      },
      loadingText: '',
      loadingShow: false,
      announcementlist: [],
      menuDataJin: {},
      // menuDataJin: {
      //   'draft' : ['Post', 'Edit', 'Delete'],
      //   'post' : ['Recall', 'Archive'],
      //   'archive' : ['Restore', 'Delete']
      // },
      boxSelect : false,
      activeIndex: "Draft",
      menuDatas: [
        {
          name: "Draft",
          key: "Draft",
          active: true
        }, {
          name: "Posted",
          key: "Posted",
          active: true
        }, {
          name: "Archived",
          key: "Archived",
          active: true
        }
      ],
      snackbarData: {
        visible: false,
        content: "",
      }
    };
  },
  created() {
    // this.query = this.$route.query || {}
    // if(this.query.keywords || this.query.classIds || this.query.studentName || this.query.postedDateStart || this.postedDateEnd) {
    //   this.getAnnouncement()
    // }

    if(hasPermission("Announcements_Full")) {
      this.menuDataJin = {
        'draft' : ['Post', 'Edit', 'Delete'],
        'post' : ['Recall', 'Archive'],
        'archive' : ['Restore', 'Delete']
      }
    }
    else if(hasPermission("Announcements_Edit")) {
      this.menuDataJin = {
        'draft' : ['Edit'],
        'post' : [],
        'archive' : []
      }
    }
    else {
      this.menuDataJin = {
        'draft' : [],
        'post' : [],
        'archive' : []
      }
    }

    this.getAnnouncement()
  },
  mounted() {
    this.pageHeight = document.body.clientHeight - 64 - 57
  },
  methods: {

    hasPermission,

    htmlDecode (text){
      var temp = document.createElement("div");
      temp.innerHTML = text;
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },

    returnFileFormat(url) {
      if(url.indexOf('/ossdev/photo/') > -1) {
        let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i
        return url.match(reg)[1]
      }
    },

    viewfile(file) {
      const needPrint = 1;
      let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      let fileFormat = file.name.match(reg)[1];

      if (fileFormat === "pdf") {
        let routeData = this.$router.resolve({ path: '/testFileView', query: { url: file.url, name: file.name, needPrint: needPrint } });
        window.open(routeData.href, '_blank');
      } else {
        let routeData = this.$router.resolve({ path: '/testFileView', query: { imgUrl: file.url, name: file.name, needPrint: needPrint } });
        window.open(routeData.href, '_blank');
      }
    },

    removeAnnouncement() {
      let data = {}
      data.id = this.delId
      this.isDelectShow = false
      
      Ajax.post("/announcement/announcements/remove/" + this.delId, data)
        .then((res) => {
          if(res.code === '0000'){
            this.loadingText = 'Draft has been deleted'
            this.loadingShow = true
            this.announcementlist.splice(this.nowIndex, 1)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateAnnouncementStatus(status) {
      let data = {}
      data.id = this.delId
      data.publishUser = this.user.userId
      data.status = status
      this.isPostShow = false
      this.isRestoreShow = false
      this.isArchiveShow = false
      this.isRecallShow = false
      this.isDelectShow = false

      if(status === 'post') {
        data.title = this.saveobj.title
        data.classId = this.saveobj.classid
      }

      Ajax.post("/announcement/announcements/updateStatusByIdV1", data)
        .then((res) => {
          if(res.code === '0000'){
            this.loadingText = 'Announcement has been ' + status + 'ed'
            this.loadingShow = true
            this.announcementlist.splice(this.nowIndex, 1)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    momentTime(date) {
      return moment.utc(date).local().format("DD/MM/YYYY, hh:mm A");
    },

    loadAnnouncement(){
      if((this.pageNum < this.pages) && !this.loading) {
        this.getAnnouncement(true)
      }
    },

    getAnnouncement(s) {
      this.loading = true
      this.pageNum = s ? this.pageNum + 1 : this.pageNum
      this.query = this.$route.query || {}

      let data = {
        schoolId: this.user.locationId,
        userId: this.user.userId,
        pageNum: this.pageNum,
        pageSize: 10
      }

      if(this.query.keywords) {
        data.keyWords = this.query.keywords
      }
      if(this.query.classIds){
        data.classIds = this.query.classIds ? this.query.classIds.split('@') : []
      }
      if(this.query.studentName){
        data.studentName = this.query.studentName
      }
      if(this.query.postedDateStart){
        data.postedDateStart = this.query.postedDateStart || ''
      }
      if(this.query.postedDateEnd){
        data.postedDateEnd = this.query.postedDateEnd || ''
      }

      Ajax.get("/announcement/announcements/pageByDraftV1", data).then((res) => {
        if(res.code === '0000'){
          let v = [], filearr = [], str = ''
          for(let item of res.data.records) {
            let temp = item
            temp.select = false
            temp.questionContent = JSON.parse(item.questionContent)

            if(item.addFile) {
              filearr = []
              let filelist = item.addFile.split(',')
              for(var i=0;i<filelist.length;i++) {
                filearr.push({
                  url: filelist[i],
                  name: item.fileDisplayName ? item.fileDisplayName.split(',')[i] : filelist[i].replace('https://classnovo-hk.oss-cn-hongkong.aliyuncs.com/dev/photo/', '')
                })
              }
            }
            temp.addFile = filearr
            v.push(temp)
          }
          this.announcementlist.push(...v)
          this.pages = res.data.pages
          this.loading = false
        }else{
          this.loading = false
        }
      });
    },

    todetail(id, index, status) {
      sessionStorage.setItem("announcementdData", JSON.stringify(this.announcementlist[index]))

      if(status === 'draft') {
        this.$router.push({ name: 'announcementDraftDetail', params: { id: id }})
      }
      else if(status === 'post') {
        this.$router.push({ name: 'announcementPostedDetail', params: { id: id }})
      }
    },

    menuClick(e, item, index) {
      this.delId = item.id
      this.nowIndex = index
      
      if(e === 'Restore') {
        this.isRestoreShow = true
      }
      if(e === 'Recall') {
        this.isRecallShow = true
      }
      if(e === 'Archive') {
        this.isArchiveShow = true
      }
      if(e === 'Post') {
        this.isPostShow = true
        this.saveobj = {
          title: item.title,
          classid: item.classIds
        }
      }
      if(e === 'Delete') {
        this.isDelectShow = true
      }
      if(e === 'Edit') {
        localStorage.setItem("announcementdData", JSON.stringify(this.announcementlist[index]))
        this.$router.push({ name: 'announcementCreate', query: { editid: item.id }})
      }
    },

    boxSelectFun(index, event) {
      if(event.path[0].className != 'iconmore_vert-24px iconfont' && event.path[1].className != 'drop_down_menus') {
        let v = this.announcementlist[index].select
        for(let i=0;i<this.announcementlist.length;i++) {
          this.announcementlist[i].select = false
        }
        this.announcementlist[index].select = !v
      }
    },

    toStudentListPage(){},

    openDialogAdd(){
      this.$router.push({ name: 'announcementCreate'})
    },

    onMenuSelect(key, item){
      this.activeIndex = key;

      if(key === 'Draft') {
        this.$router.push({ name: 'announcement'})
      } else {
        this.$router.push({ name: 'announcement' + key})
      }
      // this.query.status = key;
      // this.usersData.tableTitle = `${item.name} Staff`;
      // this.getData();
    }
  }
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.collapse-list{
  overflow: auto;
  .title{
    padding-left: 8px;
    padding-bottom: 26px;
    border-bottom: 1px solid #e3e3e3;
    color: #202124;
    font-size: 22px;
    font-weight: 600;
    font-family: OpenSans-Bold;
  }
}

.section-inner{

  .section-box {
    position: relative;
    background: #fff;
    box-sizing: border-box;
    color: #202124;
    border-radius: 13px;
    transition: ease .4s;
    min-width: 1000px;

    &.select {
      margin: 20px 0 5px 0;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

      .section-head {
        border-radius: 13px 13px 0 0;
        background-color: #E7EFFD;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

        .date,
        .section-item .tit {
          color: #5F6368 !important;
        }
      }

      .section-body {
        padding: 20px;
        max-height: 420px;
      }

      &:hover {
        .section-head {
          border-radius: 13px 13px 0 0;
        }
      }
    }

    &:hover {
      z-index: 1;
      border-radius: 13px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

      .section-head {
        border-radius: 13px;
      }
    }

    .section-head {
      position: relative;
      padding: 20px;
      height: 100px;
      background-color: #fff;
      border-bottom: 1px solid #E0E0E0;
      box-sizing: border-box;

      .section-head-l {
        float: left;

        .section-title {
          display: inline-block;
          margin-bottom: 5px;
          line-height: 27px;
          font-size: 20px;
          font-family: Open Sans;
          font-weight: 600;
          line-height: 27px;
          color: #1A73E8;
          cursor: pointer;
          max-width: 750px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:hover {
            opacity: .7;
          }
        }

        .section-des {
          .no {
            display: inline-block;
            margin-right: 8px;
            width: 24px;
            height: 24px;
            background: #b4b4b4;
            border-radius: 50%;
            line-height: 24px;
            text-align: center;
            color: #fff;
            font-size: 12px;
            font-family: Open Sans;
            font-weight: 600;
          }

          .avatar {
            margin-right: 8px;
            display: inline-block;
            width: 24px;
            height: 24px;
            vertical-align: middle;

            img {
              display: block;
              width: 24px;
              height: 24px;
              border-radius: 50%;
            }
          }

          .name {
            margin-right: 8px;
            display: inline-block;
            font-size: 12px;
            font-family: Roboto;
            font-weight: 400;
            line-height: 19px;
            color: #202124;
            vertical-align: middle;
          }
          
          .tag {
            margin-right: 8px;
            display: inline-block;
            padding: 4px 9px;
            height: 20px;
            line-height: 12px;
            font-family: Roboto;
            background: #E0E0E0;
            border-radius: 10px;
            font-size: 12px;
            vertical-align: middle;
          }

          .date {
            margin-right: 8px;
            display: inline-block;
            font-size: 12px;
            font-family: Roboto;
            font-weight: 400;
            line-height: 16px;
            color: #B4B4B4;
          }
        }
      }

      .section-head-r {
        float: right;

        .section-item {
          float: left;

          li {
            float: left;
            margin-right: 80px;

            .options {
              width: 90px;
            }

            .val {
              margin-bottom: 3px;
              font-size: 22px;
              font-family: Open Sans;
              font-weight: 600;
              line-height: 30px;
              color: #202124;
            }

            .tit {
              font-size: 14px;
              font-family: Roboto;
              font-weight: 400;
              line-height: 19px;
              color: #B4B4B4;
            }
          }
        }

        .section-col {
          float: left;

          .menu_bar {
            margin-top: 12px;
            width: 40px;
            height: 40px;
            line-height: inherit;
          }

          .menu_bar .menu_bar_button:hover {
            background: none;
          }
        }
      }
    }

    .section-body {
      max-height: 0;
      overflow-y: auto;

      .section-mail {
        font-size: 14px;
        font-family: Roboto;
        font-weight: 500;
        line-height: 19px;
        color: #202124;
        word-break: break-all;
      }

      .file-list-inner {

          .file-box {
            margin-top: 20px;
            position: relative;
            height: 72px;
            line-height: 72px;
            background: #fff;
            border: 1px solid #E0E0E0;
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;

            .file-head {
              float: left;
              width: 103px;
              height: 72px;

              img {
                display: block;
                width: 103px;
                height: 72px;
              }
            }

            .file-body {
              padding: 0 20px;
              font-size: 12px;
              color: #202124;
              overflow: hidden;
            }
          }
        }
    }

  }
}
</style>