<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        }, {
          routeName: 'announcement',
          name: 'Draft',
        },{
          routeName: 'announcementCreate',
          name: editid ? 'Edit announcement' : 'Create announcement',
        }
      ]"
      slot="breadcrumb"
    />
    <GgPage
      topType="location"
      pageType="2"
      title="Announcements"
    >
      <div slot="topAppBar"><search></search></div>
      <template v-slot:left>
        <div class="mar-l-b" v-if="activeIndex != 'Create'">
          <gg-create-button @click="openDialogAdd(null)" label="Create announcement" />
        </div>
        <gg-flex-menus
          :menus="menuDatas"
          @select="onMenuSelect"
          :default-active="activeIndex"
        />
      </template>

      <div class="collapse-list">
        <p class="title">{{ editid ? 'Edit announcement' : 'Create new announcement' }}</p>
        <!-- <div class="section-des" v-if="!editid">
          <div class="avatar" v-if="user.userInfo.photo"><img :src="user.userInfo.photo"></div>
          <div class="no" v-else>
            {{ user.userInfo.firstName ? user.userInfo.firstName.substring(0, 1).toUpperCase() : '-' }}{{ user.userInfo.lastName ? user.userInfo.lastName.substring(0, 1).toUpperCase() : '-' }}
          </div>
          <div class="name">{{ user.userInfo.firstName || '' + ' ' + user.userInfo.lastName || '' }}</div>
          <div class="tag" v-if="rolename">{{ rolename }}</div>
          <div class="date">Edit on {{ momentTime(new Date()) }}</div>
        </div> -->
        <div class="announcement-inner">
          <div class="draft-right">
            <div class="for-box">
              <div class="for-name">For</div>
              <div class="for-dropdown" @click="hoverDropdown" :class="{ 'hover' : dropdownSw }">
                Add class
                <img class="arrow-drop" src="@/assets/images/icons/arrow_drop.svg" />
                <div class="dropdown-list">
                  <div class="dropdown-list-inner">
                    <div class="select-all"><baseCheckbox v-model="forSelectAll" @change="classAll">Select all</baseCheckbox></div>
                    <ul class="class-list">
                      <li v-for="(item, index) in classList" :key="index">
                        <baseCheckbox @change="classCheck" v-model="item.select">
                          <div class="no" :style="'background-color:' + item.themeColor">{{ item.ai }}</div>
                          <div class="name">{{ item.className }}</div>
                        </baseCheckbox>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="for-dropdown-bg" v-if="dropdownSw" @click="dropdownSw = false"></div>
              <div class="for-select-list">
                <ul class="class-list">
                  <li v-for="(item, index) in classselectList" :key="index" v-show="item.select">
                    <div class="no" :style="'background-color:' + item.themeColor">{{ item.ai }}</div>
                    <div class="name">{{ item.className }}</div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="cancel">
              <div class="tit">
                <baseButton class="cancel-btn" @click="backpage">Cancel</baseButton>
              </div>
              <div class="con">
                <gg-dropdown>
                  <el-tooltip
                    popper-class="edoovo-table-cell-tooltip"
                    :visible-arrow="false"
                    content="Select"
                    placement="bottom"
                    effect="light"
                  >
                    <span>Select</span>
                  </el-tooltip>
                  <!-- 1、如果有标题或日期，也必须同时判断选项数量大于1个，可以post
                  2、如果没有标题和日期，也没有选项，可以post
                  3、如果没有标题或日期，有一个选项，不能post -->
                  <template slot="menus" v-if="responseBox && ((response.title || selectedDate) || tableDataFun > 0)">
                    <gg-dropdown-item @click="saveAnnouncement('draft')"> Save </gg-dropdown-item>
                    <gg-dropdown-item @click="isDialogShow = true" v-if="hasPermission('Announcements_Full') && classselectList.length > 0 && questionOptionsSw && editTitle && editTitle.length <= 255 && !errorObj.formTit.show && !errorObj2.formTit.show && tableDataEditFun && editorContent && editorContentSw && tableDataFun >= 2"> Post </gg-dropdown-item>
                    <el-tooltip
                      v-else
                      popper-class="edoovo-table-cell-tooltip"
                      :visible-arrow="false"
                      content="Cannot post without classes and details info"
                      placement="bottom"
                    >
                      <gg-dropdown-item v-if="hasPermission('Announcements_Full')" :disabled="true"> Post </gg-dropdown-item>
                    </el-tooltip>
                  </template>

                  <template slot="menus" v-else>
                    <gg-dropdown-item @click="saveAnnouncement('draft')"> Save </gg-dropdown-item>
                    <gg-dropdown-item @click="isDialogShow = true" v-if="hasPermission('Announcements_Full') && classselectList.length > 0 && questionOptionsSw && editTitle && editTitle.length <= 255 && !errorObj.formTit.show && !errorObj2.formTit.show && tableDataEditFun && editorContent && editorContentSw"> Post </gg-dropdown-item>
                    <el-tooltip
                      v-else
                      popper-class="edoovo-table-cell-tooltip"
                      :visible-arrow="false"
                      content="Cannot post without classes and details info"
                      placement="bottom"
                    >
                      <gg-dropdown-item v-if="hasPermission('Announcements_Full')" :disabled="true"> Post </gg-dropdown-item>
                    </el-tooltip>
                  </template>
                  
                </gg-dropdown>
              </div>
            </div>

          </div>

          <div class="draft-con">
            <div class="edit-box">

              <div class="edit-name">Details</div>

              <div class="edit-tit">
                <gg-input
                  class="edit-tit-input"
                  v-model="editTitle"
                  placeholder="Title"
                  :error="errorObj['formTit']"
                  @keyup="validate"
                />
              </div>

              <div class="edit-editing">
                <editor :init="editorInit" v-model="editorContent" />
              </div>

              <div class="file-inner">
                <div v-if="!editorContentSw" class="editor-tips">Character limit of 5000</div>
                <el-upload
                  v-if="fileList.length < 10"
                  class="upload-wrapper"
                  ref="uploadFileInp"
                  list-type="picture"
                  multiple
                  :auto-upload="false"
                  :show-file-list="false"
                  action="/media/public/file/uploadExt"
                  accept="image/jpeg,image/png,application/pdf"
                  :on-change="uploadFileChange"
                  :file-list="fileList">
                  <el-button class="add-file-btn" size="small" type="primary">
                    <img class="add-file-icon" src="@/assets/images/icons/attach.png" />
                    Add file
                  </el-button>
                </el-upload>

                <div class="file-list-inner">
                  <div class="file-box" v-for="(item, index) in fileList" :key="index">
                    <div class="file-head" @click="viewfile(item)">
                      <pdf
                        style="width: 103px; height: 72px; text-align: center;" 
                        class="pdf-preview" 
                        v-if="returnFileFormat(item.url) === 'pdf'" 
                        :id="item.id" 
                        :page="1" 
                        :src="item.url" />
                      <el-image
                        v-else
                        style="width: 103px; height: 72px;"
                        :src="item.url"
                        fit="cover">
                      </el-image>
                    </div>
                    <div class="file-body" @click="viewfile(item)">
                      <div class="file-name">File name: {{ item.name }}</div>
                    </div>
                    <div class="delete" @click="delFile(item.uid)"><img class="add-file-icon" src="@/assets/images/icons/close.svg" /></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="response-inner">
              <baseButton class="add-response-btn" type="primary" @click="responseBoxShow(true)" v-if="!responseBox">Add response</baseButton>

              <div class="response-col" v-if="responseBox">
                <div class="response-name">
                  Response
                  <div class="delete" @click="responseBoxShow(false)"><img class="add-file-icon" src="@/assets/images/icons/close.svg" /></div>
                </div>

                <div class="response-info">
                  <div class="response-date">
                    <img src="@/assets/images/icons/calendar_today.svg" class="calendar-today" />
                    <md-datepicker v-model="selectedDate">
                      <label>Due Date</label>
                    </md-datepicker>
                  </div>

                  <div class="response-tit">
                    <gg-input
                      class="response-tit-input"
                      v-model="response.title"
                      placeholder="Instruction"
                      @keyup="validate2(response.title)"
                      :error="errorObj2['formTit']"
                    />
                  </div>
                </div>

                <div class="response-option">
                  <el-table
                    ref="dragTable"
                    :data="tableData"
                    :show-header="false"
                    :empty-text="'no data'"
                    class="list-table"
                    v-loading="loading"
                  >
                    <el-table-column prop="value" label="" style="width: 100%">
                      <template slot-scope="scope">
                        <div class="rowName">
                          <img
                            :src="require('@/assets/images/my-handle.svg')"
                            style="display: none"
                            class="my-handle isDisplay"
                          />
                          <div class="editName" v-if="scope.row.isEdit">
                            <gg-input
                              key="date"
                              ref="date"
                              placeholder=""
                              @keyup.enter.native="responseInputEnter(scope.$index, scope.row)"
                              v-model="scope.row.value"
                              :autofocus="true"
                              :error="errorObj1['formTit']"
                              @keyup="validate1(scope.row.value)"
                            ></gg-input>
                            <div class="operation">
                              <span
                                class="iconfont icontick"
                                :class="{
                                  'not-allowed': (scope.row.value == '' || scope.row.value.length > 100),
                                }"
                                @click="handleOperator(scope.$index, scope.row)"
                              ></span>
                              <span
                                class="iconfont iconclear-px"
                                @click="handleClose(scope.$index, scope.row)"
                              ></span>
                            </div>
                          </div>
                          <div class="showName" v-if="!scope.row.isEdit">
                            <div class="val">{{ scope.row.value }}</div>
                          </div>
                        </div>
                        <div
                          class="action isDisplay"
                          style="display: none"
                          v-if="!scope.row.isEdit"
                        >
                          <BaseButton
                            type="primary"
                            @click="handleEdit(scope.$index, scope.row)"
                            >Edit</BaseButton
                          >
                          <BaseButton @click="handleDelete(scope.$index, scope.row)"
                            >Delete</BaseButton
                          >
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="wraper" v-if="disabledAddoption" v-show="tableData.length < 4">
                    <div class="append">
                      <baseButton class="text" type="primary" @click="handleAddOption()">Add option</baseButton>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          
          <div class="file-progress" v-if="fileProgress">
            <dl>
              <dt>Uploading file ....</dt>
              <dd>1 of {{ uploadFileNun }}</dd>
            </dl>
            <baseButton @click="cancelUpload" class="stop" type="primary" :active="true">Stop</baseButton>
            <md-progress-bar class="progress" md-mode="determinate" :md-value="amount"></md-progress-bar>
          </div>
        </div>
      </div>

    </GgPage>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />

    <gg-Dialog width="580px" :visible.sync="fileSizeShow" title="File is too large" class="draft-dialog mp-dialog">
      <div class="dialogContent">
        The maximum size of a file that can be uploaded is 25MB.
        <div class="delete" @click="fileSizeShow = false"><img class="add-file-icon" src="@/assets/images/icons/close.svg" /></div>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </gg-Dialog>

    <gg-Dialog width="580px" :visible.sync="isDialogShow" title="Post announcement?" class="draft-dialog mp-dialog">
      <div class="dialogContent">Once the announcement is posted, all the parents of the selected classes will be able to view it.</div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="isDialogShow = false">Cancel</gg-cancel-button>
        <gg-submit-button @click="saveAnnouncement('post')">Post</gg-submit-button>
      </span>
    </gg-Dialog>
    
    <gg-Dialog
    width="580px"
	  title="Save changes"
	  class="noborder-dialog mp-dialog create-dialog"
	  :visible.sync="formDialogView.visible"
	  :show-close="true"
	  :before-close="
	    () => {
	      formDialogView.visible = !formDialogView.visible;
	    }
	  "
	>
	  <div class="dialog-wrapper">
	    You have some unsaved changes. Please save them before you continue.
	  </div>
	</gg-Dialog>

    <Snackbar :visible.sync="loadingShow" :content="loadingText" />
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import search from './modules/search'
import moment from 'moment'
import Editor from '@tinymce/tinymce-vue'
import { Ajax } from "@/common";
import { mapState } from "vuex";
import Sortable from "sortablejs";
import { vi } from 'date-fns/locale';
import { hasPermission } from './utils/utils.js';
export default {
  components: {
    pdf,
    Editor,
    search
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user
    }),

    questionOptionsSw() {
      let optionsSw = true,
      optionsInstructionSw = true,
      tableData = this.tableData

      for (let i=0;i<tableData.length;i++) {
        if(tableData[i].value.length > 100) {
          optionsSw = false
          break
        }
      }
      
      if(this.response.title.length > 255) {
        optionsInstructionSw = false
      }
      
      return optionsSw && optionsInstructionSw
    },

    tableDataEditFun() {
      let tableData = this.tableData,
      sw = true

      for (let i=0;i<tableData.length;i++) {
        if(tableData[i].isEdit) {
          sw = false
          break
        }
      }

      return sw
    },

    tableDataFun() {
      let tableData = this.tableData,
      len = 0

      for(let item of tableData) {
        if(item.value) {
          len += 1
        }
      }

      return len
    }
  },
  data() {
    return {
      rolename: '',
      rootPath: process.env.VUE_APP_fileBasePath,
      createCpSw: false,
      dropdownSw: false,
      isEdit: true,
      formDialogView: {
        visible: false,
      },
      fileSizeShow: false,
      isDialogShow: false,
      uploadFileNun: 0,
      classInfo: '',
      announcementdData: [],
      editid: '',
      status: {
        'DRAFT': 0,
        'POSTED': 1,
        'ARCHIVE': 2
      },
      loadingText: '',
      loadingShow: false,
      fileList: [],
      fileProgress: false,
      amount: 50,
      selectedDate: null,
      loading: false,
      module: {
        title: "",
        moduleType: "",
        moduleName: "",
      },
      disabledAddoption: true,
      tableData: [],
      // isAllowed: true,
      oldVal: {},
      snackbar: { isShow: false, content: "" },
      ids: [], // 拖动排序
      errorObj: {
        formTit: {
          show: false,
          message: "Character limit of 255",
        }
      },
      errorObj1: {
        formTit: {
          show: false,
          message: "Character limit of 100",
        }
      },
      errorObj2: {
        formTit: {
          show: false,
          message: "Character limit of 255",
        }
      },
      responseBox: false,
      classList: [],
      classselectList: [],
      forSelectAll: false,
      editorContent: '',
      editorContentSw: true,
      editTitle: '',
      response: {
        title: ''
      },
      query: {
        size: 20,
        current: 1,
      },
      editorInit: {},
      activeIndex: "Create",
      menuDatas: [
        {
          name: "Draft",
          key: "Draft",
          active: true
        }, {
          name: "Posted",
          key: "Posted",
          active: true
        }, {
          name: "Archived",
          key: "Archived",
          active: true
        }
      ],
      snackbarData: {
        visible: false,
        content: "",
      }
    };
  },
  created() {
    let that = this
    this.editorInit = {
      language: 'en',
      convert_urls: false,
      statusbar: false,
      menubar: false, // 禁用菜单栏
      branding: false, // 隐藏右下角技术支持
      elementpath: false, // 隐藏底栏的元素路径
      advlist_bullet_styles: '',
      advlist_number_styles: '',
      plugins: [
        'autoresize advlist anchor autolink autosave code codesample colorpicker colorpicker directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'
      ],
      toolbar: [
        'bold italic underline | bullist numlist'
      ],
      paste_preprocess: function(plugin, args) {
          args.content = that.strip_tags(args.content,'<strong><p><i><u><em><h1><h2><h3><h4><h5>');
      },
      autoresize_bottom_margin: 50,
      autoresize_max_height: 420, // 编辑区域的最大高
      autoresize_min_height: 420, // 编辑区域的最小高度
      plugin_preview_width: 1000,
      autoresize_on_init: true,
      autoresize_overflow_padding: 10,
      images_upload_handler: function (blobInfo, success, failure) {
        const formData = new FormData()
        formData.append('file', blobInfo.blob())

        // uploadPic(formData)
        //   .then(response => {
        //     // console.log(response)
        //     if (!response.code) {
        //       success(response.url)
        //     } else {
        //       failure(response.message)
        //     }
        //   })
        //   .catch(() => {
        //     failure('网络错误，请稍后重试')
        //   })
      }
    }

    this.getClass()
    //this.getRolename()
    
  },
  mounted() {
    if(this.$route.query.editid) {
      this.editid = this.$route.query.editid || ''
      this.announcementdData = JSON.parse(localStorage.getItem("announcementdData"))
      this.announcementdEdit()
    }
  },
  beforeRouteLeave(to, form, next) {
    if (this.isEdit) {
      this.formDialogView.visible = true;
    } else {
      next();
    }
  },
  watch: {
    editorContent: function() {
      let num = this.removeHTMLTag(this.editorContent).length
      if(num > 5000) {
        this.editorContentSw = false
      } else {
        this.editorContentSw = true
      }
    }
  },
  methods: {

    hasPermission,

    eidtInputFun() {
      setTimeout(()=>{
        this.validate()
        // this.validate2(this.response.title)
      }, 1000)
    },

    strip_tags (_str, allowed_tags){
      var str = _str.replace(new RegExp("<h(.*?)>","g"),'<strong>').replace(new RegExp("</h(.*?)>","g"),'</strong>');
      console.log(str)
      var key = '', allowed = false;
      var matches = [];    var allowed_array = [];
      var allowed_tag = '';
      var i = 0;
      var k = '';
      var html = ''; 
      var replacer = function (search, replace, str) {
          return str.split(search).join(replace);
      };
      // Build allowes tags associative array
      if (allowed_tags) {
          allowed_array = allowed_tags.match(/([a-zA-Z0-9]+)/gi);
      }
      console.log(allowed_tags,allowed_array)
      str += '';
      // Match tags
      matches = str.match(/(<\/?[\S][^>]*>)/gi);
      // Go through all HTML tags
      for (key in matches) {
          if (isNaN(key)) {
              // IE7 Hack
              continue;
          }
          // Save HTML tag
          html = matches[key].toString();
          // Is tag not in allowed list? Remove from str!
          allowed = false;
          // Go through all allowed tags
          for (k in allowed_array) {            // Init
              allowed_tag = allowed_array[k];
              i = -1;
              if (i != 0) { i = html.toLowerCase().indexOf('<'+allowed_tag+'>');}
              if (i != 0) { i = html.toLowerCase().indexOf('<'+allowed_tag+' ');}
              if (i != 0) { i = html.toLowerCase().indexOf('</'+allowed_tag)   ;}

              // Determine
              if (i == 0) {               
                  allowed = true;
                  break;
              }
          }
          if (!allowed) {
              str = replacer(html, "", str); // Custom replace. No regexing
          }
      }
      return str;
    },

    htmlDecode (text){
      var temp = document.createElement("div");
      temp.innerHTML = text;
      var output = temp.innerText || temp.textContent;
      temp = null;
      //output.replace(/\n/g, '\r\n')
      return output;
    },

    getRolename() {
      let data = {
        id: this.user.userId
      }
      Ajax.get("/usermanage/staff/user/selectStaffByUserId", data).then((res) => {
        if(res.code === '0000'){
          this.rolename = res.data.roleName
        }
      });
    },

    createCp(){
      this.createCpSw = !this.createCpSw
    },

    hoverDropdown(){
      this.dropdownSw = !this.dropdownSw
    },

    responseBoxShow(v) {
      this.responseBox = v

      // close
      if(!v) {
        this.tableData = []
        this.response = {
          title: ''
        }
        this.selectedDate = ''
      }else {
        setTimeout(()=> {
          this.rowDrop()
        }, 100)
      }
    },

    removeHTMLTag(str) {
      str = str.replace(/<\/?[^>]*>/g, '');
      str = str.replace(/[ | ]*\n/g, '\n');
      // str = str.replace(/&nbsp;/ig, '');
      // str = str.replace(/\s/g, '');
      return str;
    },

    momentTime(date) {
      return moment.utc(date).local().format("DD/MM/YYYY, hh:mm A");
    },

    returnFileFormat(url) {
      let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i
      return url.match(reg)[1]
    },

    announcementdEdit() {
      let data = this.announcementdData

      // 会议主题
      this.editTitle = this.htmlDecode(data.title)

      // 附件内容
      for(let item of data.addFile) {
        this.fileList.push(item)
      }

      // 会议内容
      this.editorContent = data.body

      // 调查问卷日期
      this.selectedDate = data.questionContent.dueDate || null
      
      // 调查问卷主题
      this.response.title = this.htmlDecode(data.questionContent.instruction)
      if(data.questionContent.instruction || data.questionContent.dueDate || data.questionContent.options.length > 0) {
        this.responseBoxShow(true)
      }
      
      // 调查问卷答案
      for(let item of data.questionContent.options) {
        this.tableData.push({
          isEdit: false,
          value: item.label
        })
      }

      // 验证input字数
      this.eidtInputFun()
    },

    saveAnnouncement(status) {
      this.isDialogShow = false
      let data = {}, postUrl = ''

      // 会议主题
      data.title = this.editTitle

      // 附件内容
      data.addFile = []
      for(let item of this.fileList) {
        data.addFile.push(item.url)
      }

      // 附件文件名
      data.fileDisplayName = []
      for(let item of this.fileList) {
        data.fileDisplayName.push(item.name)
      }

      // 会议内容
      data.body = this.editorContent

      // 班级id
      data.classId = []
      for(let item of this.classselectList) {
        data.classId.push(item.id)
      }
      
      // console.log('本地时间：' + moment(this.selectedDate).endOf('day').format("YYYY-MM-DD HH:mm:ss"))
      // 调查问卷日期
      data.dueDate = this.selectedDate ? moment.utc(moment(this.selectedDate).endOf('day')).local().format("YYYY-MM-DD HH:mm:ss") : ''

      // 调查问卷主题
      data.questionInstruction = this.response.title

      // 调查问卷答案
      data.questionOptions = []
      for(let item of this.tableData) {
        data.questionOptions.push({
          label: item.value,
          value: '0'
        })
      }
      
      // 学校id
      data.schoolId = this.user.locationId

      // 状态
      data.status = status

      //发布人
      data.publishUser = this.user.userId

      // 编辑 & 新建
      if(this.$route.query.editid) {
        data.id = this.editid
        postUrl = '/announcement/announcements/updateByIdV1'
      } else {
        postUrl = status === 'post' ? '/announcement/announcements/saveV1' : '/announcement/announcements/save'
      }

      Ajax.post(postUrl, data)
        .then((res) => {
          if(res.code === '0000'){
            this.isEdit = false
            this.loadingText = 'Announcement has been ' + status + 'ed'
            this.loadingShow = true
            setTimeout(()=> {
              if(status === 'draft') {
                this.$router.push({ name: 'announcement' })
              } 
              else if (status === 'post') {
                this.$router.push({ name: 'announcementPosted' })
              }
              else if (status === 'archive') {
                this.$router.push({ name: 'announcementArchived' })
              }
            }, 1500)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getClassInfo() {
      let classIds = this.announcementdData.classIds
      if(classIds) {
        for(let i=0;i<classIds.length;i++) {
          for(let j=0;j<this.classList.length;j++) {
            if(classIds[i] === this.classList[j].id){
              this.classList[j].select = true
              break
            }
          }
        }

        this.classCheck()
      }
    },

    backpage() {
      this.isEdit = false
      this.$router.go(-1)
    },

    delFile(uid) {
      for(let i=0;i<this.fileList.length;i++) {
        if(this.fileList[i].uid === uid) {
          this.fileList.splice(i, 1)
          break
        }
      }
    },

    viewfile(file) {
      // console.log(event)
      // if(event.path[0].className != 'iconmore_vert-24px iconfont' && event.path[1].className != 'drop_down_menus') {

      // }
      const needPrint = 1;
      let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      let fileFormat = file.name.match(reg)[1];

      if (fileFormat === "pdf") {
        let routeData = this.$router.resolve({ path: '/testFileView', query: { url: file.url, name: file.name, needPrint: needPrint } });
        window.open(routeData.href, '_blank');
      } else {
        let routeData = this.$router.resolve({ path: '/testFileView', query: { imgUrl: file.url, name: file.name, needPrint: needPrint } });
        window.open(routeData.href, '_blank');
      }
    },

    cancelUpload() {
      this.fileProgress = false
      this.uploadFileNun = 0
      this.$refs.uploadFileInp.abort()
    },

    uploadFileChange(file, fileList) {
      if (file) {
        let size = Math.floor(file.size / 1024)
        if(size > (25 * 1024)) {
          this.fileSizeShow = true
          return false
        }
        this.fileProgress = true
        this.uploadFileNun += 1

        let formData = new FormData();
        formData.append("file", file);
        formData.append("multipartFile", file.raw);

        Ajax.post(
          "/media/public/file/uploadExt", 
          formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let num = progressEvent.loaded / progressEvent.total * 100 | 0;
            this.amount = num
          }
        })
          .then((res) => {
            if (res.code === "0000") {
              let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
              let fileFormat = res.data.filePath.match(reg)[1];
              let timer = file.raw.type === 'application/pdf' ? 3500 : 100

              setTimeout(()=>{
                if(this.fileProgress) {
                  this.fileList.push({
                    name: file.name,
                    url: `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`,
                    type: fileFormat,
                  });
                }

                this.uploadFileNun -= 1
                this.amount = 0

                if(this.uploadFileNun === 0) {
                  this.fileProgress = false
                }
              }, timer)
            }
            if (res.code === "9999") {
              this.handleSnack("Maximum upload size exceeded")
            }
          })
          .catch((err) => {
            console.log(err)
          });
      }
    },

    handleEdit(index, row) {
      this.$set(row, "isEdit", true);
      let temp = this.oldVal
      temp[index] = row.value
      this.oldVal = temp
      this.validate1(row.value)
    },

    handleDelete(index, row) {
      let messageObj = {
        title: "Delete option?",
        desc: "Once deleted, the option will not be recovered.",
        cancelTxt: "Cancel",
        confirmTxt: "Delete",
      };
      let callback = () => {
        this.actualDelete(index, row);
      };
      this.openMessageBox(messageObj, callback);
    },

    actualDelete(index, row) {
      this.tableData.splice(index, 1);
      this.$set(row, "isEdit", false);
      this.disabledAddoption = true;
    },

    handleOperator(index, row) {
      if (row.value != '' && row.value.length <= 100) {
        this.disabledAddoption = true;
        this.$set(row, "isEdit", false);
      }
    },

    handleClose(index, row) {
      console.log(index, row)
      this.$set(row, "isEdit", false);
      this.$set(row, "value", this.oldVal[index]);
      this.$set(this.tableData[index], "isAllowed", false);
      // add option
      if (!this.disabledAddoption) {
        this.disabledAddoption = true;
        this.tableData.splice(index, 1);
      }
    },

    handleAddOption() {
      this.tableData.push({ value: "" });
      this.$set(this.tableData[this.tableData.length - 1], "isEdit", true);
      this.disabledAddoption = false;
    },

    responseInputEnter(index, row) {
      if(row.value.length > 0 && row.value.length < 100) {
        this.disabledAddoption = true;
        this.$set(row, "isEdit", false);
      }
    },

    //行拖拽
    rowDrop() {
      const tbody = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper tbody"
      )[0];
      const _this = this;
      this.sortable = Sortable.create(tbody, {
        handle: ".my-handle",
        draggable: "tr", // 指定那些选项需要排序
        setData: function (dataTransfer) {
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
          dataTransfer.setData("value", "");
        },
        onEnd({ newIndex, oldIndex }) {
          let list = _this.tableData;
          const targetRow = list.splice(oldIndex, 1)[0];
          list.splice(newIndex, 0, targetRow);
          _this.tableData = []; // https://blog.csdn.net/weixin_33712881/article/details/91477794
          _this.$nextTick(() => {
            _this.tableData = [...list];
            _this.tableData.map((item) => _this.ids.push(item.id));
            console.log(_this.tableData);
          });
        },
      });
      
    },

    openMessageBox(messageObj, callback) {
      this.$confirm(messageObj.desc, messageObj.title, {
        cancelButtonText: messageObj.cancelTxt,
        confirmButtonText: messageObj.confirmTxt,
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        callback: (action) => {
          if (action == "confirm") {
            callback(action);
          }
        },
      });
    },

    handleSuspend(content) {
      this.snackbar.isShow = true;
      this.snackbar.content = content;
    },

    classCheck() {
      let se = 0, sr = 0, len = this.classList.length
      let classselectList = []

      for(let item of this.classList) {
        if(item.select) {
          classselectList.push(item)
          se += 1
        } else {
          sr += 1
        }
      }

      if(se === len){
        this.forSelectAll = true
      } else if(sr <= len){
        this.forSelectAll = false
      }

      this.classselectList = classselectList
    },

    classAll() {
      let temparr = [], sw = true, classselectList = []

      for(let item of this.classList) {
        if(!item.select) {
          sw = false
          break
        }
      }

      // 选项全部选中的情况
      if(sw) {
        for(let item of this.classList) {
          item.select = false
          temparr.push(item)
          classselectList = []
        }
      } else {
        // 选项未被全部选中的情况
        for(let item of this.classList) {
          item.select = true
          temparr.push(item)
          classselectList.push(item)
        }
      }

      this.classList = temparr
      this.classselectList = classselectList
    },

    validate1(val) {
      if(val.length > 100){
        this.errorObj1['formTit'].show = true
      }else{
        this.errorObj1['formTit'].show = false
      }
    },

    validate2(val) {
      if(val.length > 255){
        this.errorObj2['formTit'].show = true
      }else{
        this.errorObj2['formTit'].show = false
      }
    },

    validate(val) {
      if(this.editTitle.length > 255){
        this.errorObj['formTit'].show = true
      }else{
        this.errorObj['formTit'].show = false
      }
    },

    getClass() {
      Ajax.get(
        "/usermanage/class/listByClassV1?staffId=" + this.user.userId + '&locationId=' + this.user.locationId)
        .then((res) => {
          let tempdata = [], temparr = {}

          for(let item of res.data) {
            temparr = item
            temparr.select = false
            temparr.ai = 
              item.className.indexOf(' ') > -1 ? 
              (item.className.split(' ')[0].substring(0, 1).toUpperCase() + item.className.split(' ')[1].substring(0, 1)).toUpperCase() : 
              item.className.substring(0, 1).toUpperCase();           
            tempdata.push(temparr)
          }
          this.classList = tempdata

          if(this.$route.query.editid) {
            this.getClassInfo()
          }
        });
    },

    openDialogAdd(){
      this.$router.push({ name: 'announcementCreate'})
    },

    onMenuSelect(key, item){
      this.activeIndex = key;

      if(key === 'Draft') {
        this.$router.push({ name: 'announcement'})
      } else {
        this.$router.push({ name: 'announcement' + key})
      }
      // this.query.status = key;
      // this.usersData.tableTitle = `${item.name} Staff`;
      // this.getData();
    }
  }
};
</script>
<style lang='less' scoped>
.collapse-list{
  .title{
    padding-left: 8px;
    padding-bottom: 26px;
    border-bottom: 1px solid #e3e3e3;
    color: #202124;
    font-size: 22px;
    font-weight: 600;
    font-family: OpenSans-Bold;
  }
}

.announcement-inner {
  font-family: 'Roboto';
  padding-top: 20px;

  .draft-con {
    background: #fff;
    overflow: hidden;
    
    .edit-box {
      border: 1px solid #E0E0E0;
      border-radius: 13px;

      .edit-name {
        padding-left: 20px;
        line-height: 68px;
        border-bottom: 1px solid #E0E0E0;
        color: #5F6368;
        font-size: 20px;
        font-family: 'opensans-semibold';
      }

      .edit-tit {
        padding: 0 20px;

        .md-field.md-theme-default:after {
          border-bottom: 1px solid #E0E0E0;
        }
      }

      .edit-editing {
        margin: 20px;
        min-height: 420px;
        background: #F5F5F5;
      }

      .file-inner {
        position: relative;
        padding: 0 20px 20px 20px;
        margin-top: 70px;

        .add-file {
          position: absolute;
          left: 20px;
          top: 0;
          width: 104px;
          height: 32px;
          background: #fff;
          opacity: 0;
          cursor: pointer;
        }

        .add-file-btn {
          padding: 8px 12px;
          background-color: #1a73e8;
          border: 1px solid #1a73e8; 
          font-family: OpenSans-Bold;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          color: #fff;
          line-height: 18px;

          .add-file-icon {
            margin: -0 5px 0 0;
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: middle;
          }

          &:hover {
            background-color: #2b7de9;
          }
        }

        .file-list-inner {

          .file-box {
            margin-top: 20px;
            position: relative;
            height: 72px;
            line-height: 72px;
            background: #fff;
            border: 1px solid #E0E0E0;
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;

            .delete {
              position: absolute;
              right: 20px;
              top: 22px;
              width: 24px;
              height: 24px;
              cursor: pointer;

              img {
                display: block;
                width: 24px;
                height: 24px;
              }

              &:hover {
                opacity: .7;
              }
            }

            .file-head {
              float: left;
              width: 103px;
              height: 72px;
            }

            .file-body {
              padding: 0 20px;
              font-size: 12px;
              color: #202124;
              overflow: hidden;
            }
          }

        }
      }
    }

    .response-inner {
      margin-bottom: 112px;

      .add-response-btn {
        margin: 20px 0;
        color: #1A73E8;
        font-size: 14px;
        font-family: Open Sans;
        font-weight: 600;     
        cursor: pointer;
      }

      .response-col {
        margin: 20px 0;
        border: 1px solid #E0E0E0;
        border-radius: 13px;
        
        .response-option {
          margin-bottom: 8px;
        }

        .response-name {
          position: relative;
          padding-left: 20px;
          line-height: 68px;
          border-bottom: 1px solid #E0E0E0;
          color: #5F6368;
          font-size: 20px;
          font-family: 'opensans-semibold';

          .delete {
            position: absolute;
            right: 20px;
            top: 22px;
            width: 24px;
            height: 24px;
            cursor: pointer;

            img {
              display: block;
              width: 24px;
              height: 24px;
            }

            &:hover {
              opacity: .7;
            }
          }
        }

        .response-info {

          .response-date {
            float: right;
            position: relative;
            margin: 0 20px 0 80px;
            width: 220px;

            .calendar-today {
              position: absolute;
              right: 0;
              top: 21px;
              width: 24px;
              height: 24px;
            }
          }

          .response-tit {
            padding: 0 0 0 20px;
            overflow: hidden;

            .md-field {
              margin-bottom: 20px !important;
            }

            .md-field.md-theme-default:after {
              border-bottom: 1px solid #E0E0E0;
            }
          }
        }

      }
      
    }
  }

  .draft-right {
    float: right;
    padding-left: 20px;
    width: 364px;
    background: #fff;

    .cancel {
      position: fixed;
      right: 80px;
      bottom: 120px;

      .tit {
        float: left;
        margin-right: 20px;
        font-size: 13px;
        color: #5F6368;
        line-height: 33px;

        .cancel-btn {
          padding: 2px 12px;
        }
      }

      .con {
        float: left;

        .el-button {
          width: 110px;
          padding: 0 32px 0 0;
          text-align: center;
          line-height: 36px;

          &:hover,
          &:focus,
          &:visited,
          &:active {
            color: #5F6368;
            border-color: #E0E0E0;
            background-color: #fff;
          }

          .el-tooltip {
            display: block;
            font-family: 'opensans-semibold';
            color: #b4b4b4;

            &:hover {
              background-color: #eee;
            }
          }

          .el-tooltip.disabled {
            color: #b4b4b4;
            font-family: 'opensans-semibold';
            border: 0;
          }

          .iconfont {
            position: absolute;
            right: 0;
            top: 0;
          }
          
          * {
            color: #202124;
            font-family: 'opensans-semibold';
            font-size: 14px !important;
          }
        }
      }
    }

    .for-box {
      padding: 0 2px 20px 0;
      width: 342px;
      border: 1px solid #E0E0E0;
      border-radius: 13px;

      .for-name {
        position: relative;
        padding-left: 20px;
        line-height: 68px;
        color: #5F6368;
        font-size: 20px;
        font-family: 'opensans-semibold';
      }

      .for-dropdown-bg {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        overflow: hidden;
      }

      .for-dropdown {
        position: relative;
        z-index: 1000;
        margin: auto 20px;
        padding: 0 20px;
        height: 32px;
        line-height: 32px;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        font-size: 14px;
        color: #b4b4b4;
        cursor: pointer;

        &.hover {
          .dropdown-list {
            display: block;
          }
        }

        .arrow-drop {
          position: absolute;
          right: 4px;
          top: 4px;
          width: 20px;
          height: 20px;
        }

        .dropdown-list {
          display: none;
          position: absolute;
          left: 0;
          top: 32px;
          padding: 0 2px 0 0;
          width: 298px;
          background: #fff;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 4px;

          .dropdown-list-inner {
            padding: 8px 0;
            max-height: 156px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 4px;
              border-radius: 4px;
            }

            &::-webkit-scrollbar-track {
              background-color: #fff;
            }

            .select-all {
              padding-left: 16px;
              line-height: 28px;
              font-size: 14px;
              color: #202124;

              .el-checkbox {
                width: 100%;
                color: #202124;
              }

              &:hover {
                background-color: #eee;
              }
            }

            .class-list {
              padding: 0;

              li {

                .el-checkbox {
                  width: 100%;
                }

                &:hover {
                  background-color: #eee;
                }
              }
            }
          }
        }
      }

      .class-list {
        padding: 7px 0 0 0;

        li {
          padding: 0 0 0 16px;
          line-height: 28px;
          font-size: 14px;
          color: #202124;

          .no {
            display: inline-block;
            margin-right: 8px;
            width: 20px;
            height: 20px;
            background: #7e7e7e;
            border-radius: 50%;
            line-height: 20px;
            text-align: center;
            color: #fff;
            font-size: 11px;
            margin-top: -2px;
            vertical-align: middle;
          }

          .name {
            display: inline-block;
            font-size: 14px;
            color: #202124;
          }
        }
      }

      .for-select-list {
        padding: 0 20px 0 20px;
        width: 317px;
        max-height: 150px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
          background-color: #fff;
        }
      }
    }
    
  }
}

// 选项
.el-table {
  /deep/tbody tr:hover {
    .isDisplay {
      display: inherit !important;
    }
  }
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff;
}
/deep/.section-main {
  margin: 0;
  max-height: calc(100vh - 112px);
  overflow-y: auto;
}
/deep/.el-table td {
  padding: 3px 0 2px 0;
}

.list-table {
  .not-allowed {
    cursor: not-allowed !important;
    color: #cbcbcb !important;
  }
  .iconfont {
    font-size: 24px;
    line-height: 24px;
    margin-left: 8px;
    cursor: pointer;
  }
  .rowName {
    position: relative;
    padding: 0 0 0 40px;
    font-family: Roboto;
    font-size: 14px;
    color: #202124;

    .val {
      padding-right: 310px;
      line-height: 1.5;
    }

    .md-input {
      font-size: 14px;
    }

    // .my-handle {
    //   position: absolute;
    //   cursor: grab;
    //   left: 0;
    //   top: 10px;
    // }

    .my-handle {
      position: absolute;
      cursor: grab;
      margin-top: -12px;
      left: 0;
      top: 50%;
    }

    .editName {
      padding-right: 245px;
      display: flex;
      align-items: center;
    }
  }
  .cell {

    .action {
      position: absolute;
      top: -2px;
      right: 167px;
    }
  }

  .el-table .cell {
    overflow: inherit;
  }
  .md-field {
    margin-bottom: 0;
    padding-top: 0;
    min-height: 32px;
  }
  .md-field.md-invalid {
    margin-bottom: 20px;;
  }
  .md-field .md-error {
    bottom: -13px;
  }
}

.wraper {
  padding: 5px 0 5px 10px;
  
  .append {

    .base-button {
      font-family: Roboto;
      font-size: 13px;
      color: #1A73E8;
    }
  }
}
 
.isDisplay .base-button {
  font-family: 'opensans-semibold' !important;
  font-size: 14px !important;
  line-height: 20px;
  margin-right: 8px;
}

.file-progress {
  position: fixed;
  left: 50%;
  bottom: 0;
  margin-left: -170px;
  padding: 15px;
  width: 340px;
  height: 80px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);;
  font-size: 14px;

  dt {
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
  }

  .stop {
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 6px 12px;

    &:hover {
      background: #1a73e8;
      color: #fff;
    }
  }

  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 8px;
    border-radius: 0 0 4px 4px;
  }
}

.section-des {
  margin-top: 15px;

    .no {
      display: inline-block;
      margin-right: 8px;
      width: 32px;
      height: 32px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      line-height: 32px;
      text-align: center;
      color: #333;
      font-size: 14px;
    }

    .avatar {
      margin-right: 8px;
      display: inline-block;
      width: 32px;
      height: 32px;
      vertical-align: middle;

      img {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }

    .name {
      margin-right: 8px;
      display: inline-block;
      font-size: 14px;
      font-family: Roboto;
      font-weight: 400;
      line-height: 19px;
      color: #202124;
      vertical-align: middle;
    }
    
    .tag {
      margin-right: 8px;
      display: inline-block;
      padding: 4px 9px;
      height: 20px;
      font-family: Roboto;
      line-height: 12px;
      background: #E0E0E0;
      border-radius: 10px;
      font-size: 12px;
      vertical-align: middle;
    }

    .date {
      margin-right: 8px;
      display: inline-block;
      font-size: 12px;
      font-family: Roboto;
      font-weight: 400;
      line-height: 16px;
      color: #B4B4B4;
    }
  }

.dialogContent {
  position: relative;

  .delete {
    position: absolute;
    right: 0;
    top: -60px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    img {
      display: block;
      width: 20px;
      height: 20px;
    }

    &:hover {
      opacity: .7;
    }
  }
}

.editor-tips {
  margin: -8px 0 15px 0;
  color: #ea4335;
  font-family: Roboto;
}
</style>