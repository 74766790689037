var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content has_breadcrumb journal-inner"},[_c('base-breadcrumb',{attrs:{"slot":"breadcrumb","data":[
      {
        routeName: 'locations',
        name: _vm.user.schoolName,
      },
      {
        name: 'Journal',
      } ]},slot:"breadcrumb"}),_c('GgPage',{attrs:{"pageType":"1","title":"Journal"},on:{"forward":_vm.forward,"next":_vm.next}},[_c('div',{attrs:{"slot":"topAppBar"},slot:"topAppBar"},[_c('month-switch',{attrs:{"defaultVal":_vm.defSwitchVal},on:{"change":_vm.monthChange}}),_c('search')],1),_c('BaseList',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":"Classes","data":_vm.list,"type":1},on:{"onClickItem":_vm.itemClick}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticStyle:{"width":"213px","text-align":"center","border-radius":"50%","line-height":"200px","margin":"auto","margin-bottom":"12px","margin-top":"80px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('@/assets/images/products/noData.svg')}})]),_c('p',{staticStyle:{"font-size":"22px","color":"rgba(32,33,36,0.5)","font-family":"Roboto"}},[_vm._v("No data for the day.")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }